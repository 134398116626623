var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('v-card-title',[_c('v-btn',{attrs:{"color":"#ffffff","light":"","elevation":"0"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',{attrs:{"left":"","large":""}},[_vm._v("mdi-chevron-left")])],1),_c('h3',[_vm._v("Branches")]),_c('v-btn',{staticClass:"ml-10",attrs:{"elevation":"2","color":"#33cc99","dark":""},on:{"click":function($event){return _vm.$router.push('/new-branch')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" New Branch ")],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-sheet',{attrs:{"elevation":"4"}},[[_c('div',[_c('v-data-table',{attrs:{"headers":_vm.branchTableHeaders,"items":_vm.filteredItems,"search":_vm.search},scopedSlots:_vm._u([{key:"item.branch_id",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"to":{
                      name: 'edit-branch',
                      params: { branch_id: item.branch_id },
                    },"color":"#3d2cdd","icon":"","small":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}},{key:"item.branch_name",fn:function(ref){
                    var item = ref.item;
return [_c('router-link',{attrs:{"to":{
                      name: 'view-branch',
                      params: { branch_id: item.branch_id },
                    }}},[_c('v-icon',{attrs:{"left":"","x-small":"","color":"#2278cf"}},[_vm._v("mdi-open-in-new")]),_vm._v(_vm._s(item.branch_name))],1)]}},{key:"no-data",fn:function(){return [_c('v-container',[_c('v-row',[_c('v-col',[_c('div',{staticClass:"my-3"},[_c('h3',[_vm._v("Welcome To Your Customer Dashboard")]),_c('p',[_vm._v(" Here you can add new customers, or view and edit"),_c('br'),_vm._v(" the details of ones already in your system ")]),_c('v-btn',{attrs:{"color":"#33cc99","dark":"","to":"/new-branch"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v("Add Branch")],1)],1)])],1)],1)]},proxy:true}])})],1)]],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }