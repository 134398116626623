<template>
    <v-container>
    <v-row>
      <v-col>
        <!--Header-->
        <v-card-title>
          <v-btn @click="$router.go(-1)" color="#ffffff" light elevation="0">
            <v-icon left large>mdi-chevron-left</v-icon>
          </v-btn>
          <h3>Branches</h3>
          
          <!--Button-->
          <v-btn
            elevation="2"
            color="#33cc99"
            dark
            class="ml-10" 
            @click="$router.push('/new-branch')"
            >
            <v-icon left>mdi-plus</v-icon>
            New Branch
        </v-btn>
        </v-card-title>
      </v-col>
    </v-row>
    <v-row>
        <v-col>
          <v-sheet elevation="4">
            <!--branch data table-->
            <template>
              <div>
                
                <v-data-table
                  :headers="branchTableHeaders"
                  :items="filteredItems"
                  :search="search"
                >
                  <!--Edit icon button-->
                  <template  v-slot:item.branch_id="{ item }" >
                    <v-btn
                      :to="{
                        name: 'edit-branch',
                        params: { branch_id: item.branch_id },
                      }"
                      color="#3d2cdd"
                      icon
                      small
                      ><v-icon small>mdi-pencil</v-icon></v-btn
                    >
                  </template>
                  <!--Clickable link that routes from table to job preview page-->
                  <template v-slot:item.branch_name="{ item }">
                    <router-link
                      :to="{
                        name: 'view-branch',
                        params: { branch_id: item.branch_id },
                      }"
                      ><v-icon left x-small color="#2278cf"
                        >mdi-open-in-new</v-icon
                      >{{ item.branch_name }}</router-link
                    >
                  </template>
                  <!--Empty datatable slot-->
                  <template v-slot:no-data>
                    <v-container>
                      <v-row>
                        <v-col>
                          <div class="my-3">
                            <h3>Welcome To Your Customer Dashboard</h3>
                            <p>
                              Here you can add new customers, or view and
                              edit<br />
                              the details of ones already in your system
                            </p>
                            <v-btn
                              color="#33cc99"
                              dark
                              to="/new-branch"
                              ><v-icon left>mdi-plus</v-icon>Add Branch</v-btn
                            >
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </template>
                </v-data-table>
              </div>
            </template>
          </v-sheet>
        </v-col>
      </v-row>
  </v-container>
</template>
<script>
import db from "../../../components/firebaseInit";
import mixin_UserRoles from "../../../globalActions/mixin_UserRoles";
export default {
    name: "Branches",
    mixins: [mixin_UserRoles],
    data(){
        return {
            search: "",
            branches: [],
            branchTableHeaders: [
              { text: 'Edit', sortable: true, value: 'branch_id'},
              { text: 'Code', sortable: true, value: 'branch_code'},
              { text: 'Name', sortable: true, value: 'branch_name'},
              { text: 'City', sortable: true, value: 'branch_address_city'},
              { text: 'Email', sortable: true, value: 'branch_email'},
              { text: 'Telephone', sortable: true, value: 'branch_telephone'},
            ],
        }
    },
    computed: {
        filteredItems() {
            return this.branches
        },
    },
    methods: {
      getBranches() {
        const accountsRef = db.collection("branches").orderBy("branch_code");

        accountsRef.onSnapshot((querySnapshot) => {
          this.branches = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            this.branches.push(data);
          });
        });
      },
    },
    created(){
      this.getBranches();
    }
}
</script>
