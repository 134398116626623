<template>
    <div>View Branch</div>
</template>

<script>
export default {
    name: "ViewBranch",
    data(){
        return {
            
        }
    }
}
</script>
