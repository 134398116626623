<template>
    <v-container  fluid>
        <v-row dense>
            <v-col lg="8" offset-lg="2">
                <v-card-title>
                    <v-btn
                        to="/branches"
                        color="#ffffff"
                        light
                        elevation="0"
                    >
                    <v-icon left large>mdi-chevron-left</v-icon>
                    </v-btn>
                    <h3>New Branch</h3>
                </v-card-title>
                <v-card>
                    <v-form @submit.prevent="saveBranch" class="pa-3" ref="form" v-model="valid">
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                        v-model="branch_name"
                                        label="Name"
                                        outlined
                                        dense
                                        color="#3d2cdd"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                        v-model="branch_code"
                                        label="Branch Code"
                                        readonly
                                        outlined
                                        dense
                                        color="#3d2cdd"
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <!-- Contact info -->
                            <v-row>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                        v-model="branch_email"
                                        label="Email"
                                        outlined
                                        dense
                                        color="#3d2cdd"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                        v-model="branch_telephone"
                                        label="Contact Number"
                                        outlined
                                        dense
                                        color="#3d2cdd"
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <!-- Address -->
                            <v-row>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                        v-model="branch_address_line_1"
                                        label="Address Line 1"
                                        outlined
                                        dense
                                        color="#3d2cdd"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                        v-model="branch_address_line_2"
                                        label="Address Line 2"
                                        outlined
                                        dense
                                        color="#3d2cdd"
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                        v-model="branch_address_city"
                                        label="City"
                                        outlined
                                        dense
                                        color="#3d2cdd"
                                    >
                                    </v-text-field>
                                </v-col>

                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                        v-model="branch_address_zip"
                                        label="ZIP"
                                        outlined
                                        dense
                                        color="#3d2cdd"
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            to='/branches'
                            color="#ce2458"
                            text
                            x-large
                        >
                            Cancel
                            <v-icon right>mdi-close</v-icon>
                        </v-btn>
                        <v-btn
                            @click="saveBranch"
                            color="#33cc99"
                            text
                            x-large
                            :disabled="!valid"
                            :loading="loading"
                        >
                            {{ $route.params.branch_id ? 'Update' : 'Submit' }}
                            <v-icon right>mdi-check</v-icon>
                        </v-btn>
                        <v-btn
                            v-if="$route.params.branch_id"
                            @click="deleteBranch"
                            hint
                            color="#ce2458"
                            text
                            x-large
                            :loading="loading"
                            :disabled="invoice_id"
                        >
                            Delete
                            <v-icon right>mdi-delete</v-icon>
                        </v-btn>
                    </v-card-actions>

                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import db from '../../../components/firebaseInit'
import firebase from 'firebase'
import {showSnackbar} from '../../../globalActions/index'
//Mixins
import mixin_UserRoles from '../../../globalActions/mixin_UserRoles'

export default {
    name: "BranchForm",
    mixins: [ mixin_UserRoles ],
    data(){
        return {
            valid: false,
            branch_name: null,
            branch_code: null,
            branch_email: null,
            branch_telephone: null,
            branch_address_line_1: null,
            branch_address_line_2: null,
            branch_address_city: null,
            branch_address_zip: null,
            loading: false,
            branch_created_by_id: null,
            branch_created_by_name: null,
        }
    },
    methods: {
        // Save branch to database
        async saveBranch () {
            // Form validation
            if(!this.$refs.form.validate()) return;

            this.loading = true;
            try {
                const branchData = {
                    branch_name: this.branch_name,
                    branch_code: this.branch_code,
                    branch_email: this.branch_email,
                    branch_telephone: this.branch_telephone,
                    branch_address_line_1: this.branch_address_line_1,
                    branch_address_line_2: this.branch_address_line_2,
                    branch_address_city: this.branch_address_city,
                    branch_address_zip: this.branch_address_zip,
                    branch_date_created: new Date().toISOString().slice(0, 10),
                    branch_timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                    branch_created_by_id: this.branch_created_by_id,
                    branch_created_by_name: this.branch_created_by_name,
                };

                if (this.$route.params.branch_id) {
                    // Update existing branch
                    await db.collection('branches').doc(this.$route.params.branch_id).update(branchData);
                    showSnackbar("Branch updated successfully!");
                } else {
                    // Create new branch
                    const newDocRef = db.collection('branches').doc();
                    await newDocRef.set({ branch_id: newDocRef.id, ...branchData });

                    // Increment branch count if creating a new branch
                    const increment = firebase.firestore.FieldValue.increment(1);
                    const branchCountDoc = db.collection("branch_counter").doc("Ux16047w0Xh53nux65FK");
                    await branchCountDoc.update({ branch_count: increment });

                    showSnackbar("New branch created successfully!");
                }

                this.$router.push('/branches');
            } catch (error) {
                console.error("Error saving branch: ", error);
            } finally {
                this.loading = false;
            }
        },
        // Delete current branch
        async deleteBranch() {
            if(!confirm("Are you sure you want to delete this branch?")) return;
            this.loading = true;
            try {
                await db.collection('branches').doc(this.$route.params.branch_id).delete();
                showSnackbar("Branch deleted successfully!");
                this.$router.push('/branches');
            } catch(error) {
                console.error("Error deleteing branch", error);
            } finally {
                this.loading = false;
            }
            
        },
        // Get user who created branch
        async getUser() {
            this.branch_created_by_id = firebase.auth().currentUser.uid;

            try {
                const querySnapshot = await db.collection("users")
                    .where("uid", "==", this.branch_created_by_id)
                    .get();

                this.branch_created_by_name = querySnapshot.docs[0].data().displayName;

            } catch (error) {
                console.error("Error fetching user: ", error);
            }

        },
        // Get branch code from database
        getBranchCode() {
            //Get Order & Quote counters from database
            this.loading = true;

            const docRef = db.collection("branch_counter").doc("Ux16047w0Xh53nux65FK");
            
            docRef.onSnapshot((snapshot) => {
                const { branch_count } = snapshot.data();
                // Format branch_count with leading zeros
                const formattedCount = `${branch_count}`.padStart(4, '0');
                this.branch_code = `BR-${formattedCount}`;
                this.loading = false;
            });
        },
        // Load branch data for edit mode
        async loadBranchData(branchId) {
            console.log("Attempting to load branch data for ID:", branchId);
            this.loading = true;
            const docRef = db.collection('branches').doc(branchId);
            const doc = await docRef.get();
            if (doc.exists) {
                const data = doc.data();
                // Populate form data
                this.branch_name = data.branch_name;
                this.branch_code = data.branch_code;
                this.branch_email = data.branch_email;
                this.branch_telephone = data.branch_telephone;
                this.branch_address_line_1 = data.branch_address_line_1;
                this.branch_address_line_2 = data.branch_address_line_2;
                this.branch_address_city = data.branch_address_city;
                this.branch_address_zip = data.branch_address_zip;
                console.log("Document data loaded:", data);
            } else {
                console.log("No such document!");
            }
            this.loading = false;
        },
    },
    created() {
        this.getUser();
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            // `vm` is the instance of the incoming component
            if (to.params.branch_id) {
                vm.loadBranchData(to.params.branch_id);
            } else {
                vm.getBranchCode(); // Initialize data for creating a new branch
            }
        });
    },
}
</script>
